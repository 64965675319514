$(document).ready(function () {

    //setup the x-csrf-token to allow ajax requests to run correctly
    ajax_setup();

    $('.mobile-menu').click(function (e) {

        e.preventDefault();
        $('nav').slideToggle();
    });

    var menu    = $('header nav');
    var submenu = $('header nav ul li ul');

    $(window).resize(function () {

        var w = $(window).width();

        if (w > 778 && menu.is(':hidden')) {

            menu.removeAttr('style');
        } else if (w < 778) {

            $("header nav li.hasSub > a").click(function (e) {

                e.preventDefault();
            });
        }
    });

    var w = $(window).width();

    if (w < 778) {

        $("header nav li.hasSub > a").click(function (e) {
            e.preventDefault();
        });
    }
});