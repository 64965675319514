function ajax_setup() {

    //sets up the form csrf token when using ajax to send to the controller
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.fn.dataArray = function () {
        return this.map(function (i, element) {
            return $(element).data();
        });
    };
}

/**
 * clears a form of all inputted data for input, textarea, select, checkbox, radio.
 *
 * @param container
 */
function clear_form(container) {
    $('input, textarea, select', container).val('');
    $('input[type=checkbox], input[type=radio]', container).prop('checked', false);
}